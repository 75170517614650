import httpRequest from './request'

// 获取手机验证码
export function getPhoneCode<T>(payload, cb?: () => void, errCb?: () => void) {
  return httpRequest.post<T>('seeyard/send_login_code', payload, cb, errCb)
}
// 登录
export function loginAdmin<T>(
  payload,
  cb?: (res: any) => void,
  errCb?: () => void
) {
  return httpRequest.post<T>('seeyard/login_admin', payload, cb, errCb)
}
// 验证token
export function verifyToken<T>(
  token,
  cb?: (res: any) => void,
  errCb?: () => void
) {
  return httpRequest.post<T>('seeyard/guigui/redirect', token, cb, errCb)
}
// 获取当前登录的账号身份
export function getAccountIdentity(params: { phone: any }) {
  return httpRequest.get(`seeyard/split_order/user/role`, params)
}
